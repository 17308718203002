<template>
	<ValidationObserver>
		<form @submit.prevent="saveProduct">
			<b-card title="Product Detail">
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Name*</label>
					<div class="col">
						<validation-provider
							name="name"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								class="form-control" 
								v-model="formProduct.name"
							>
							<small
								v-for="(validation, index) in validations.name"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Position*</label>
					<div class="col">
						<validation-provider
							name="position"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="number" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formProduct.order"
							>
							<small
								v-for="(validation, index) in validations.order"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Status</label>
						<validation-provider
							name="status"
							rules="required"
							v-slot="{ errors }"
						>
							<div class="col demo-inline-spacing p-0">
								<div class="custom-control custom-radio mt-0">
									<input 
										name="Status" 
										class="custom-control-input"
										:state="errors.length > 0 ? false:null"
										type="radio" 
										id="radioShow" 
										value="show" 
										v-model="formProduct.status"
									>
									<label 
										class="custom-control-label" 
										for="radioShow"
									>
										Show
									</label>
								</div>
								<div class="custom-control custom-radio mt-0">
									<input 
										name="Status" 
										class="custom-control-input"
										:state="errors.length > 0 ? false:null"
										type="radio" 
										id="radioHide"
										value="hide"
										v-model="formProduct.status"
									>
									<label 
										class="custom-control-label" 
										for="radioHide"
									>
										Hide
									</label>
								</div>
							</div>
							<small
								v-for="(validation, index) in validations.status"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
				</div>
				<div class="form-group form-row">
					<div class="col-4">
						<label class="col-form-label">Default</label>
						<p class="images__hint">Default Product: product that given when customer register</p>
					</div>
					<div class="col demo-inline-spacing p-0">
						<div class="custom-control custom-radio mt-0">
							<input 
								name="Default" 
								class="custom-control-input" 
								type="radio" 
								id="radioYes" 
								v-bind:value="true"
								v-model="formProduct.is_default"
							>
							<label 
								class="custom-control-label" 
								for="radioYes"
							>
								Yes
							</label>
						</div>
						<div class="custom-control custom-radio mt-0">
							<input 
								name="Default" 
								class="custom-control-input" 
								type="radio" 
								id="radioNo"
								v-bind:value="false"
								v-model="formProduct.is_default"
							>
							<label 
								class="custom-control-label" 
								for="radioNo"
							>
								No
							</label>
						</div>
					</div>
				</div>
				<!-- <div class="form-group form-row">
					<label class="col-4 col-form-label">Icon*</label>
					<div class="col">
						<b-form-file
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="changePhoto($event , 'icon')"
						/>
						<p class="images__hint"><i>Image Size: 200 x 200 pixel</i></p>
						<div
							v-if="formProduct.icon"
							class="mt-1 relative"
						>
							<img
								:src="icon_url"
								height="200" width="200"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto('icon')"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div> -->
				<!-- <div class="form-group form-row">
					<label class="col-4 col-form-label">Image*</label>
					<div class="col">
						<b-form-file
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="changePhoto($event , 'image')"
						/>
						<p class="images__hint"><i>Image Size: 400 x 400 pixel</i></p>
						<div
							v-if="formProduct.image"
							class="mt-1 relative"
						>
							<img
								:src="image_url"
								height="400" width="400"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto('image')"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div> -->
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Syarat dan Ketentuan</label>
					<div class="col">
						<quill-editor
							v-model="formProduct.term_and_condition"
							:options="snowOption"
						/>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Deskripsi</label>
					<div class="col">
						<quill-editor
							v-model="formProduct.description"
							:options="snowOption"
						/>
					</div>
				</div>
			</b-card>
			<b-card>
				<h3>Price Product</h3>
				<div
					v-for="(price, index) in formProduct.prices"
					:key="price.uuid"
					class="mt-2"
				>
					<div class="d-flex justify-content-between align-items-center mb-1">
						<h4 class="mb-0">Price {{ index + 1 }}</h4>
						<b-button
							variant="danger"
							class="btn waves-effect waves-float waves-light btn-danger"
							@click="removePrice(index)" v-show="index > 0"
						>
							Delete Price
						</b-button>
					</div>
					<b-row>
						<b-col cols="12" md="6">
							<div class="form-group">
								<label>Active Periode</label>
								<input 
									type="number" 
									class="form-control" 
									v-model="price.active_priode"
								>
							</div>
							<div class="form-group">
								<label>Product For</label>
								<select 
									class="form-control"
									v-model="price.product_for"
								>
									<option value="">-- Pilih Harga Untuk --</option>
									<option value="month">Bulan</option>
									<option value="year">Tahun</option>
								</select>
							</div>
							<div class="form-group">
								<label>Consultation Minutes</label>
								<input 
									type="number" 
									class="form-control" 
									v-model="price.consultation_minutes"
								>
							</div>
						</b-col>
						<b-col cols="12" md="6">
							<!-- <div class="form-row">
								<div class="form-group col-md-6">
									<label>IOS ID</label>
									<input 
										type="text" 
										class="form-control" 
										v-model="price.ios_id"
									>
								</div>
								<div class="form-group col-md-6">
									<label>IOS Type</label>
									<v-select
										v-model="price.ios_type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										label="label"
										placeholder="-- Pilih Tipe --"
										:options="constant"
									/>
								</div>
							</div> -->
							<div class="form-group">
								<label>Harga</label>
								<input 
									type="number" 
									class="form-control" 
									v-model="price.price"
								>
							</div>
							<div class="form-group">
								<label>Diskon (Persen%)</label>
								<input 
									type="number" 
									class="form-control" 
									v-model="price.discount"
								>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary mt-1"
					@click="addPrice"
				>
					Add Price Product
				</b-button>
			</b-card>
			<b-row>
				<b-col cols="12">
					<b-card title="Feature Control">
						<div class="mt-1">
							<b-row class="mb-2">
								<b-col
									cols="12"
									md="8"
								>
									<validation-provider
										name="product menu"
										rules="required"
										style="display:grid;"
									>
										<label>Feature*</label>
										<small
											v-for="(validation, index) in validations.product_menus"
											:key="`errorName${index}`"
											class="text-danger"
										>Wajib minimal pilih 1 atau pilih semua</small>
									</validation-provider>
								</b-col>
								<!-- <b-col
									cols="12"
									md="4"
								>
									<label>Position</label>
								</b-col> -->
							</b-row>
							<b-row>
								<b-col 
									cols="12" 
									md="6"
									v-for="productCheck, key in productMenu"
									:key="key"
								>
									<div class="form-group">
										<div class="custom-control custom-checkbox">
											<input 
												type="checkbox" 
												:id="'product_checkbox_' + productCheck.uuid"
												:value="productCheck.uuid"
												class="custom-control-input"
												v-model="selected"
											>
											<label 
												class="custom-control-label text-capitalize" :for="'product_checkbox_' + productCheck.uuid"
											>
												{{ productCheck.name }}
											</label>
										</div>
									</div>
								</b-col>
								<!-- <b-col
									cols="12"
									md="4"
								>
									<div class="form-group">
										<input 
											type="number"
											class="form-control"
											v-model="menuSelected"
										>
									</div>
								</b-col> -->
							</b-row>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-col cols="12" class="p-0 mt-2">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Save
				</button>
				&nbsp;
				&nbsp;
				<a
					@click="handleBackButton()"
					variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary"
				>
					Cancel
				</a>
			</b-col>
		</form>
	</ValidationObserver>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import {
	BCard, 
	BFormRadio, 
	BRow, 
	BCol,
	BFormSelect,
	BFormFile,
	BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormRadio,
		BFormSelect,
		BFormFile,
		BButton,
		quillEditor,
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},
	
	setup() {
		return {
			successNotification, 
			errorNotification
		}
	},

	data() {
		return {
			required,
			formProduct:{
				name: '',
				description: '',
				status: '',
				is_default: '',
				image: '',
				icon: '',
				term_and_condition: '',
				order: '',
				menus: [],
			},
			selected: [],
			menuSelected: '1',
			icon:[],
			image:[],
			image_url: null,
			icon_url: null,
			snowOption: {
				theme: 'snow',
			},
			productMenu: [],
			constant:[],
			validations: '',
			messageDiscount: []
		}
	},
	methods: {
		handleBackButton() {
			history.back()
		},
		inputOrder(value){
			this.menuSelected = value
		},
		addPrice() {
        	this.formProduct.prices.push({ 
				uuid: '',
				active_priode: 0, 
				consultation_minutes: 0, 
				ios_id: '',
				ios_type:'',
				discount: 0, 
				price: 0,
				product_for: '',
			});
		},
		removePrice(index) {
			this.formProduct.prices.splice(index, 1);
		},
		cancelPhoto(name) {
			if (name === 'image') {
				this.formProduct.image = null
			} else {
				this.formProduct.icon = null
			}
		},
		changePhoto(event, name) {
			if (name === 'image') {
				this.formProduct.image = event.target.files[0]
				this.showPhoto(event.target.files[0], name)
			} else {
				this.formProduct.icon = event.target.files[0]
				this.showPhoto(event.target.files[0], name)
			}
		},
		showPhoto(file , name) {
			const reader = new FileReader()
			reader.onload = e => {
				if (name === 'image') {
					this.image_url = e.target.result
				} else {
					this.icon_url = e.target.result
				}
			}
			reader.readAsDataURL(file)
		},
		
		loadProduct() {
			const params = this.$route.params.uuid
			this.$http.get('admin/products/' + params)
				.then(response => {
					this.formProduct = response.data.data
					this.image_url = this.formProduct.image
					this.icon_url = this.formProduct.icon
					this.selected = this.formProduct.menus.map((e) => {
						return e.uuid
					})
					console.log(this.formProduct);
				})
		},
		loadProductmenu() {
			this.$http.get('admin/product-menus')
			.then(response => {
				const permissionData = response.data.data.data
				this.productMenu = permissionData
			})
		},
		loadConstantValue() {
			this.$http.get('admin/constant-values')
			.then(response => {
				this.constant = response.data.data.ios_types
			})
		},
		saveProduct() {
			const params = this.$route.params.uuid
			const payload = new FormData()
			payload.append("name", this.formProduct.name)
			payload.append("status", this.formProduct.status)
			payload.append("is_default", this.formProduct.is_default)
			payload.append("order", this.formProduct.order)
			if (typeof this.formProduct.image != 'string' && this.formProduct.image != null) {
				payload.append("image", this.formProduct.image)
			}
			if (typeof this.formProduct.icon != 'string' && this.formProduct.icon != null) {
				payload.append("icon", this.formProduct.icon)
			}
			payload.append("description", this.formProduct.description)
			if (this.formProduct.prices !== null) {
				this.formProduct.prices.forEach((prices, index) => {
					Object.keys(prices).forEach(key => {
						if(key === 'ios_type') {
							payload.append('prices' + '['+ index + '][' + key + ']', 'consumable');
						} else {
							payload.append('prices' + '['+ index + '][' + key + ']', prices[key]);
						}
					});
				});
			}
			this.selected.map((e, i) => {
				console.log(e, i);
				payload.append("product_menus[" + i + "][uuid]", e)
				payload.append("product_menus[" + i + "][order]", this.menuSelected)
			})
			payload.append("_method", 'PATCH')
			this.$http.post(`admin/products/${params}`, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(response => {
				// this.loadProduct()
				successNotification(this, 'Success', 'Product List Berhasil diedit!')
				this.$router.push({ name: 'product-list' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					for (const key in this.validations) {
						if (Object.hasOwnProperty.call(this.validations, key)) {
							const element = this.validations[key];
							this.messageDiscount.push(element)
						}
					}
					errorNotification(this, 'Nama', this.validations.name.join(' '))
					errorNotification(this, 'Position', this.validations.order.join(' '))
					errorNotification(this, 'Status', this.validations.status.join(' '))
					errorNotification(this, 'Prices', this.validations.prices.join(' '))
					errorNotification(this, 'Feature Control', this.validations.product_menus.join(' '))
				}
			})
		}
	},
	async created() {
		await this.loadProductmenu()
		await this.loadProduct()
		await this.loadConstantValue()
	}
}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/quill.scss';
	@import '@core/scss/vue/libs/vue-select.scss';
	.images__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
	.btn-icon {
		position: absolute;
		right: 0;
	}
	.form-check-inline {
		flex-wrap: wrap;
	}
	.custom-checkbox.custom-control {
		padding-left: 1.8rem;
		margin-right: 1.8rem;
		margin-bottom: 1rem;
	}
</style>